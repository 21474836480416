import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import HeroSection from 'components/HeroSection';
import { title } from 'process';
import React from 'react';
import sections from 'utils/sectionConstants';
import styles from './privateVehicleDetails.module.scss';
import SideBySide from 'features/sideBySide';
import Layout from 'components/Layout';
import { DescriptiveContactSpecialist } from 'components/DescriptiveContactSpecialist';
import { useMediaQuery } from 'react-responsive';
export const PrivateVehicleDetails = ({ pageData }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <Layout
      transparentHeader={true}
      paddingTop={false}
      withOutBodyPadding={true}
    >
      <HeroSection
        image={pageData.backgroundImage}
        title={pageData.title}
        subTitles={[
          {
            title: 'estimate',
            data:
              pageData.highEstimate === null || pageData.lowEstimate === null
                ? null
                : `$${Number(
                    pageData.lowEstimate
                  ).toLocaleString()} - $${Number(
                    pageData.highEstimate
                  ).toLocaleString()}`,
            bold: true,
          },
          { title: 'chasis', data: pageData.chasis, bold: true },
          { title: 'engine', data: pageData.engine, bold: true },
        ]}
        privateSales={true}
        isVehicle={true}
      />
      <div className={styles.story}>
        <h1>The Story</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: pageData.description?.childMarkdownRemark?.html,
          }}
        ></div>
      </div>
      <div className={styles.sectionsWrapper}>
        {pageData?.sections?.map(section => {
          return (
            <div className={styles.sectionWrapper}>
              {!section.revert || isMobile ? (
                <>
                  <div className={styles.leftSection}>
                    <GatsbyImage
                      image={section?.icon?.fluid}
                      className={styles.icon}
                    />
                    <h1>{section.title}</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          section
                            .childContentfulWebSideBySideLeftParagraphTextNode
                            ?.childMarkdownRemark?.html,
                      }}
                    ></div>
                  </div>
                  <div className={styles.rightSection}>
                    <GatsbyImage
                      image={section?.image?.fluid}
                      className={styles.image}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.rightSection}>
                    <GatsbyImage
                      image={section?.image?.fluid}
                      className={styles.image}
                    />
                  </div>
                  <div className={styles.leftSection}>
                    <GatsbyImage
                      image={section?.icon?.fluid}
                      className={styles.icon}
                    />
                    <h1>{section.title}</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          section
                            .childContentfulWebSideBySideLeftParagraphTextNode
                            ?.childMarkdownRemark?.html,
                      }}
                    ></div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <DescriptiveContactSpecialist
        text={"we've been leading high quality private sales"}
        subTitle={'Get in touch with our specialists.'}
        vehicle={true}
      />
    </Layout>
  );
};
