import Button from 'components/Button';
import OpenFormAction from 'components/CTA/OpenFormAction';
import React from 'react';
import { SLUGS } from 'utils/webPageTypesConstants';
import icon from '../../../static/images/logo-blue.png';
import styles from './DescriptiveContactSpecialist.module.scss';

export const DescriptiveContactSpecialist = ({ text, subTitle, vehicle }) => (
  <div className={styles.container}>
    <img src={icon} />
    <h1 className={vehicle ? styles.privateVehicleText : ''}>
      {text
        ? text
        : 'Trust the experts at Gooding & Company with your next private sale'}
    </h1>
    {subTitle && <p className={styles.privateVehicleSubTitle}>{subTitle}</p>}
    <OpenFormAction
      buttonStyle="app-primary-button"
      extraStyle={`${styles.button}`}
      callToAction={{
        style: 'Button',
        buttonText: vehicle ? 'Contact Us' : 'Contact a Specialist',
        action: { form: 'contact-us' },
      }}
    />
  </div>
);
