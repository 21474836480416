import React from 'react';
import { graphql } from 'gatsby';
import { PrivateVehicleDetails } from 'features/privateVehicleDetails';
const PrivateVehicle = props => {
  const pageData = props?.data?.contentfulPrivateVehicle;
  return <PrivateVehicleDetails pageData={pageData} />;
};
export default PrivateVehicle;
export const query = graphql`
  query privateVehicle($id: String) {
    contentfulPrivateVehicle(contentful_id: { eq: $id }) {
      title
      soldFor
      description {
        childMarkdownRemark {
          html
        }
      }
      backgroundImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      lowEstimate
      highEstimate
      chasis
      engine
      sections {
        icon {
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        image {
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        title
        revert
        childContentfulWebSideBySideLeftParagraphTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
